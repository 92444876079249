<template>
  <div class="wrapper">
    <div class="mb-4">
      <h3>
        리더십 진단
        {{ route.query.type === 'edit' ? '수정' : '생성' }}
      </h3>
    </div>

    <article>
      <div class="white-box">
        <table class="info mb-4">
          <tbody>
            <tr>
              <td class="p-2 label text-center">언어 설정</td>
              <td class="p-2 language">
                <div class="language-div">
                  <b-form-radio v-model="process.generateDiagdata.language" value="ko" type="radio" name="language">대한민국</b-form-radio>
                  <b-form-radio v-model="process.generateDiagdata.language" value="id" type="radio" name="language">인도네시아</b-form-radio>
                  <b-form-radio v-model="process.generateDiagdata.language" value="kk" type="radio" name="language">카자흐스탄</b-form-radio>
                  <b-form-radio v-model="process.generateDiagdata.language" value="zh-TW" type="radio" name="language">대만</b-form-radio>
                  <b-form-radio v-model="process.generateDiagdata.language" value="cn" type="radio" name="language">중국</b-form-radio>
                  <b-form-radio v-model="process.generateDiagdata.language" value="ru" type="radio" name="language">러시아</b-form-radio>
                  <b-form-radio v-model="process.generateDiagdata.language" value="mn" type="radio" name="language">몽골</b-form-radio>
                  <b-form-radio v-model="process.generateDiagdata.language" value="tr" type="radio" name="language">터키</b-form-radio>
                </div>
              </td>
            </tr>
            <tr>
              <td class="p-2 label text-center">고객사 명</td>
              <td class="p-2">
                <b-form-input v-model="process.generateDiagdata.company_name" type="text" class="w-500"></b-form-input>
              </td>
            </tr>
            <tr>
              <td class="p-2 label text-center">고객사 로고</td>
              <td class="p-2">
                <input @change="event => process.changeLogo(event)" type="file" name="" id="" class="mb-2" />
                <div class="d-flex gap-1">
                  <p>기존 파일명 :</p>
                  <p class="mb-2">
                    <a :href="process.generateDiagdata.company_logo_url" target="_blank">
                      {{ process.generateDiagdata.company_logo_filename }}
                    </a>
                  </p>
                </div>
                <p class="text-danger">*권장 사이즈 : 120px x 50px</p>
              </td>
            </tr>
            <tr>
              <td class="p-2 label text-center">진단 도메인</td>
              <td class="p-2">
                <div class="d-flex align-items-center mb-2">
                  <b-form-input v-model="process.generateDiagdata.company_sub_domain" type="text" class="w-500"></b-form-input>
                  <p>.carrotleaders.com</p>
                </div>
                <span class="text-danger">예시) ktng.carrotleaders.com</span>
              </td>
            </tr>
            <tr>
              <td class="p-2 label text-center">진단명(노출용)</td>
              <td class="p-2">
                <b-form-input v-model="process.generateDiagdata.group_name_show" type="text" class="w-500"></b-form-input>
              </td>
            </tr>
            <tr>
              <td class="p-2 label text-center">진단명(내부용)</td>
              <td class="p-2">
                <b-form-input v-model="process.generateDiagdata.group_name" type="text" class="w-500"></b-form-input>
              </td>
            </tr>
            <tr>
              <td class="p-2 label text-center">메인 설명 문구<br />(노출용)</td>
              <td class="p-2">
                <b-form-textarea v-model="process.generateDiagdata.group_desc" no-resize class="w-500"></b-form-textarea>
              </td>
            </tr>
            <tr>
              <td class="p-2 label text-center">Start Guide<br />이미지</td>
              <td class="p-2">
                <input @change="event => process.changeStartGuide(event)" type="file" name="" id="" class="mb-2" />
                <div class="d-flex gap-1">
                  <p>기존 파일명 :</p>
                  <p class="mb-2">
                    <a :href="process.generateDiagdata.group_start_guide_url" target="_blank">
                      {{ process.generateDiagdata.group_start_guide_filename }}
                    </a>
                  </p>
                </div>
                <p class="text-danger">*권장 사이즈 : 가로길이 최대 700px</p>
              </td>
            </tr>
            <tr>
              <td class="p-2 label text-center">진단 기간</td>
              <td class="p-2">
                <div class="d-flex align-items-center gap-2 w-500">
                  <Datepicker format="yyyy-MM-dd" v-model="process.generateDiagdata.start_date" />
                  <p>~</p>
                  <Datepicker format="yyyy-MM-dd" v-model="process.generateDiagdata.end_date" />
                </div>
              </td>
            </tr>
            <tr>
              <td class="p-2 label text-center">문항 선택</td>
              <td class="p-2">
                <div class="w-500">
                  <b-form-select v-model="process.generateDiagdata.idx_leadership_question_main">
                    <b-form-select-option :value="0" selected disabled> 문항 템플릿 선택 </b-form-select-option>
                    <b-form-select-option :value="list.idx" v-for="list in process.questionList" :key="list.idx">{{ list.question_group_name }}</b-form-select-option>
                  </b-form-select>
                </div>
              </td>
            </tr>
            <tr>
              <td class="p-2 label text-center">발송 안내</td>
              <td class="p-2">
                <ul class="w-500">
                  <li class="mb-2">
                    <div class="d-flex align-items-center gap-2">
                      <p class="flex-none w-110 text-right">담당자명 :</p>
                      <b-form-input v-model="process.generateDiagdata.contact_manager" type="text"></b-form-input>
                    </div>
                  </li>
                  <li class="mb-2">
                    <div class="d-flex align-items-center gap-2">
                      <p class="flex-none w-110 text-right">메일 발송 계정 :</p>
                      <b-form-input v-model="process.generateDiagdata.contact_email" type="text"></b-form-input>
                    </div>
                  </li>
                  <li class="mb-2">
                    <div class="d-flex align-items-center gap-2">
                      <p class="flex-none w-110 text-right">메일 제목 :</p>
                      <b-form-input v-model="process.generateDiagdata.contact_title" type="text"></b-form-input>
                    </div>
                  </li>
                  <li class="mb-2">
                    <div class="d-flex align-items-center gap-2">
                      <p class="flex-none w-110"></p>
                      <p class="text-danger">*문자 안내 발송번호는 "02-518-5467"로 발송됩니다.</p>
                    </div>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td class="p-2 label text-center">진단 안내</td>
              <td class="p-2">
                <ul class="w-500">
                  <li class="mb-2">
                    <div class="d-flex align-items-center gap-2">
                      <p class="flex-none w-110 text-right">진단 종료 안내 :</p>
                      <b-form-textarea v-model="process.generateDiagdata.contact_termination_notice" no-resize></b-form-textarea>
                    </div>
                  </li>
                  <li>
                    <div class="d-flex align-items-center gap-2">
                      <p class="flex-none w-110 text-right">진단 문의 :</p>
                      <b-form-textarea v-model="process.generateDiagdata.contact_enquiry" no-resize></b-form-textarea>
                    </div>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td class="p-2 label text-center">
                제출하기<br />
                버튼명 (노출용)
              </td>
              <td class="p-2">
                <b-form-input v-model="process.generateDiagdata.submit_btn_name" type="text" class="w-500"></b-form-input>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- 수정할 때는 '수정하기'로 버튼명 수정 -->
        <div class="text-center">
          <b-button @click="process.createLeadershipGeneration" variant="primary"> {{ route.query.type === 'edit' ? '수정' : '생성' }}하기</b-button>
        </div>
      </div>
    </article>
  </div>
</template>

<script setup>
import { onMounted, watch } from '@vue/runtime-core';
import { ref, reactive } from '@vue/reactivity';
import { _axios } from '@/utils/axios';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import axios from 'axios';
import Datepicker from '@vuepic/vue-datepicker';

const store = useStore();
const router = useRouter();
const route = useRoute();

const process = reactive({
  generateDiagdata: {
    idx: 0, // index -> 해당부분은 0 만 추가 / 아니면 수정
    language: 'ko',
    company_name: '', // 고객사명
    company_logo_url: '', // 고객사 로고
    company_logo_file: '', // 로고 파일
    company_logo_filename: '', // 로고 파일명
    company_sub_domain: '', // 도메인
    group_name_show: '', // 노출용 진단명
    group_name: '', // 내부용 진단명
    group_desc: '', // 노출용 메일 설명 문구
    group_start_guide_url: '', // 스타트 가이드 이미지
    group_start_guide_file: '', // 스타트 가이드 이미지 파일
    group_start_guide_filename: '', // 스타트 가이드 이미지 파일명
    start_date: '', // 진단기간 시작일
    end_date: '', // 진단기간 종료일
    idx_leadership_question_main: 0, // 문항선택 번호
    contact_manager: '', // 담당자 명
    contact_email: '', // 메일방송계정
    contact_title: '', // 메일제목
    contact_enquiry: '', // 문의
    contact_termination_notice: '', // 진단종료안내
    submit_btn_name: '', // 버튼명 (노출용)
  },

  questionList: [], // 문항선택 리스트

  // 로고 이미지 변경 함수
  changeLogo: event => {
    process.generateDiagdata.company_logo_file = event.target.files[0];
  },

  // 스타트 가이드 이미지 변경
  changeStartGuide: event => {
    process.generateDiagdata.group_start_guide_file = event.target.files[0];
  },

  // 날짜 맞쳐주는 callback 함수
  formatDate: dateString => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  },

  // 수정 하기 및 생성하기
  // idx 가 0 이면 생성
  // idx 가 0 이 아니면, 수정
  createLeadershipGeneration: async () => {
    const formData = new FormData();
    for (const data in process.generateDiagdata) {
      if (data === 'start_date' || data === 'end_date') {
        formData.append(data, process.formatDate(process.generateDiagdata[data]));
      } else {
        formData.append(data, process.generateDiagdata[data]);
      }
    }

    const result = await _axios.post(`/admin/group`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    const { success, message } = result.data;

    if (success) {
      alert(message);
      router.push({ name: 'DiagnosisManagementList' });
    } else {
      alert(message);
    }
  },

  // 수정 시에 데이터 가져오기
  getDiagData: async () => {
    const result = await _axios.get(`admin/group/${route.query.idx}`, {});

    const { success, message, data } = result.data;
    if (success) {
      process.generateDiagdata = data;
    } else {
      alert(message);
    }
  },

  // 문항 리스트 가져오기 -> page size 가 1보다 작으면 전체 다 가져옴
  getQuestionList: async () => {
    const result = await _axios.get(`/admin/question/list?pageNum=1&pageSize=-1`, {});

    const { success, message, data } = result.data;
    if (success) {
      process.questionList = data.list;
    } else {
      alert(message);
    }
  },
});

// 수정에서 생성 넘어가게 되면, 같은 route 라 mount가 동작하지 않음.
// 따라서 create 시엔 데이터 초기화 필요
watch(
  () => route.query,
  query => {
    if (query.type === 'create') {
      process.generateDiagdata = {
        idx: 0,
        language: 'ko',
        company_name: '',
        company_logo_url: '',
        company_logo_file: '',
        company_logo_filename: '',
        company_sub_domain: '',
        group_name_show: '',
        group_name: '',
        group_desc: '',
        group_start_guide_url: '',
        group_start_guide_file: '',
        group_start_guide_filename: '',
        start_date: '',
        end_date: '',
        idx_leadership_question_main: 0,
        contact_manager: '',
        contact_email: '',
        contact_title: '',
        contact_enquiry: '',
        contact_termination_notice: '',
        createdate: '',
        updatedate: '',
        creator: -1,
        updator: -1,
        submit_btn_name: '',
      };
    }
  }
);

onMounted(() => {
  process.getQuestionList();

  if (route.query.type === 'edit') {
    process.getDiagData();
  }
});
</script>

<style lang="scss" scoped>
.label {
  width: 150px;
}

.w-110 {
  width: 110px;
}
.w-500 {
  width: 500px;
}

.dp__main {
  width: 100%;
}
.dp__instance_calendar .dp__button {
  display: none;
}
.text-danger {
  font-size: 14px;
}

.text-right {
  text-align: right;
}
.language {
  gap: 24px;
  .language-div {
    display: flex;
    gap: 24px;
    align-items: center;
    .form-check {
      input {
        cursor: pointer;
      }
      label {
        cursor: pointer;
      }
    }
  }
}
</style>
<style lang="scss">
.language {
  .language-div {
    .form-check {
      input {
        cursor: pointer;
      }
      label {
        cursor: pointer;
      }
    }
  }
}
</style>
